body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ResponseVariableName {
  font-size: 13;
  margin-top: 2px;
}

.CustomInputClassforPhone {
  height: calc(3.5rem + 2px) !important;
  line-height: 1.25 !important;
  padding-top: 22px;

}

.forPhone {
  position: absolute;
  top: 8px;
  left: 45px;
  opacity: .65;
  transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
}

div.ListItem.active {
  background-color: #4170fb;
}

div.ListItem.active>li>div {
  color: white;
}

.pointer-event {
  cursor: pointer;
}

.company-primary-info {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
}

.avatar.avatar-l {
  flex: 0 0 65px;
  font-size: 20px;
  height: 65px;
  line-height: 65px;
  width: 65px;
}

.avatar {
  background-color: #ececec;
  border-radius: 50%;
  color: #2c3e50;
  display: inline-block;
  font-weight: 700;
  height: 37px;
  line-height: 37px;
  text-align: center;
  width: 37px;
}

.darColor {
  color: #6f727d;

}

.timeline.timeline-3 .timeline-items .timeline-item .timeline-media img {
  max-width: 48px;
  max-height: 48px;
  border-radius: 100%;
}

.timeline.timeline-3 .timeline-items .timeline-item {
  margin-left: 25px;
  border-left: 2px solid #EBEDF3;
  padding: 0 0 20px 50px;
  position: relative;
}

.timeline.timeline-3 .timeline-items .timeline-item .timeline-media {
  position: absolute;
  top: 0;
  left: -26px;
  border: 2px solid #EBEDF3;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff;
  line-height: 0;
}

.timeline.timeline-3 .timeline-items {
  margin: 0;
  padding: 0;
}

.cardtimiline {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  width: 100% !important;
}

.para_des:hover .leaddes {
  display: inline-block !important;

}

.form-group {
  margin-bottom: 1rem;
}

.fc .fc-col-header-cell-cushion {
  color: #898b96;
  text-decoration: none !important;

}

.fc a {
  color: initial;
  text-decoration: none !important;

}

.sticky-sidebar {
  position: sticky;
  top: 0;
}

div.fade.modal-backdrop.show {
  z-index: 1200;
}

div.fade.modal.show {
  z-index: 1201;
}