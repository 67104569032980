@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

html,
body,
#root,
.App {
  height: 100%;
}

body {
  overflow-x: hidden;
  /* background-color: #F7F8FB !important; */
  background-color: #ffffff00 !important;
  font-family: 'Lato', sans-serif;

}

.lead-floating-label.form-floating>label {
  padding-bottom: 0px !important;
  padding-top: 7px !important;
  padding-left: 5px !important;
}

.lead-floating-label.form-floating>.form-select,
.lead-floating-label.form-floating>.form-control {
  padding-top: 15px !important;
  padding-left: 5px !important;
  padding-bottom: 2px !important;
  height: 42px !important;
  font-size: 13px !important;
}

.lead-floating-label.form-floating>.form-select~label {
  transform: scale(0.85) translateY(-0.4rem) translateX(0.15rem) !important;
  font-size: 13px !important;
}

a.menu-item.active {
  background: linear-gradient(90deg, #E80A89 0%, #F15B2A 100%);
}




.Tempcard {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Tempcard .imgBox {
  width: 100%;
  height: 100%;
}

.Tempcard .imgBox img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  transition: .1s ease-in-out;
}



.Tempcard .content {
  position: absolute;
  bottom: -200px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  flex-direction: column;
  backdrop-filter: blur(15px);
  box-shadow: 0 -10px 10px rgba(0, 0, 0, .1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: bottom .1s ease-in-out;
}

.Tempcard:hover .content {
  bottom: 0px;
}

.Tempcard .content .contentBox h3 {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  line-height: 1.1em;
  transition: .1s;
  margin: 20px 0 15px;
  opacity: 0;
  transform: translateY(-20px);
}

.Tempcard:hover .content .contentBox h3 {
  opacity: 1;
  transform: translateY(0);
}

.Tempcard .content .contentBox h3>span {
  font-size: 12px;
  font-weight: 300;
  text-transform: initial;
}

.Tempcard .content .social {
  position: relative;
  bottom: 0px;
  padding: 0px !important;
  display: flex;
}

.Tempcard .content .social li {
  list-style: none;
  transform: translateY(40px);
  transition: .1s;
  opacity: 0;
  transition-delay: calc(0.2s * var(--i))
}

.Tempcard:hover .content .social li {
  transform: translateY(0);
  opacity: 1;
}

.Tempcard .content .social li a {
  color: #fff;
  font-size: 24px;
}

.f:hover {
  color: #4287f5;
}

.i:hover {
  color: #fc44b3;
}

.t:hover {
  color: #448efc;
}

.font-15 {
  font-size: 15px !important;
}

.font-12 {
  font-size: 12px !important;
}

.rounded-full {
  border-radius: 30px !important;
}

.FCB-p {
  padding: 40px 30px !important;
}

.lead-form-btn {
  background-color: #fff;
  border-color: #056eb9;
  color: #056eb9;
  color: #333;

}

.lead-form-btn:hover {
  background: linear-gradient(90deg, #6b5392 0, #6b5392 18%, #1165b2 60%, #00a4d4 100%);
}

.head-right-btn {
  display: block;
  border: 0px;
  text-align: center;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 40%) 0px 2px 3px;
  text-transform: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 12px;
  word-spacing: 0.1em;
  transition: all 0.1s ease-in-out 0s;
  outline: none !important;
}

.rotateAngleIcon {
  transition: transform 0.2s ease-in-out 0s;
  font-size: 28px;
  cursor: pointer;
}

.rotateit .rotateAngleIcon {
  transform: rotate(90deg);
}

.smallShadow input {
  border-radius: 0 !important;
}

.smallShadow input:focus {
  box-shadow: 0 0 0 0.1rem rgb(13 110 253 / 25%);
}

.cursorPointer {
  cursor: pointer;
}

.addBorderBottomonList li {
  background-color: transparent;
  padding: 8px 12px;
  cursor: pointer;
  list-style: none;
  color: rgb(0, 0, 0);
}

.addBorderBottomonList .ListItem:not(:last-child) li {

  border-bottom: 1px solid rgb(196, 196, 196);
}

.addBorderBottomonList .DragHandlers {

  cursor: move !important;
}

.addBodyPadding {
  padding: 40px 50px;

}

.editicos {
  cursor: pointer;
  font-size: 22px;
  position: relative;
  top: -2px;
  color: rgb(170, 170, 170);
}

.editicos:hover {
  color: rgb(51, 51, 51);
}

.itemsPerform.noPaddingonChildren>div {
  padding-right: 0 !important;
}

.ResponseVariableName {
  font-size: 13;
  margin-top: 2px;
}

.select__menu,
.select__menu-list {
  z-index: 99;
}

.noMArginOnLast {
  margin-bottom: 1rem;

}

.noMArginOnLast:last-child {
  margin-bottom: 0;

}

.react-datepicker-popper {
  z-index: 9999999;
}


/*======================
    404 page
=======================*/


.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: 'Arvo', serif;
  display: flex;
  height: 100%;

}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {

  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}


.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}

.page_404>.container {
  align-self: center;
}

.CenterWidth {
  flex-basis: 41.6667%;
  max-width: 41.6667%;
  display: block;
}

.LoaderWrapper {
  position: fixed;
  z-index: 9999999999;
  /* background-color: #333333ab; */
  background-color: #ffffff00 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}


.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  color: #fff;
}

.loader:before,
.loader:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}

.loader:after {
  color: #0096ff;
  transform: rotateY(70deg);
  animation-delay: .4s;
}


@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {

  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }

  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }

  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }

  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }

  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }

  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }

  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }

  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}

.font-14 {
  font-size: 14px !important;
}


@media only screen and (max-width:850px) {
  .addBodyPadding {
    padding: 30px 20px;

  }

  .ans-wrapper-responsive {
    display: block !important;
  }

  .ans-div-responsive {
    max-width: 100% !important;
  }

  .formBody-responsive {
    padding: 40px 15px 50px !important;
  }

  .w-75 {
    width: 100% !important;
  }

  .addBodyPadding {
    padding: 40px 30px !important;
  }
}

/* New style added 29/1/2024 */
.fix-slider-font .font-fix {
  font-size: 21px !important;
}

.fix-slider-font .MuiSlider-valueLabelLabel {
  font-size: 21px !important;
}